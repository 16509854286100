import React, { useMemo, useState, useRef } from 'react'
import { jobsProp } from '@/components/Careers/ListJob'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { API_URL, GATSBY_SIGNATURE } from '@/npc-api/config'
import toast from 'react-hot-toast'
import { navigate } from 'gatsby'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'

type props = {
  job: jobsProp | undefined
}
const JobDetailsP: React.FC<props> = ({ job }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [midName, setmidName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [linkedInUrl, setLink] = useState('')
  const [experience, setTexp] = useState('')
  const [relevantExperience, setRexp] = useState('')
  const [leavingReason, setReason] = useState('')

  const urlLinkedinInputRef = useRef<HTMLInputElement>(null)

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    maxSize: 1024 * 1024 * 10,
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }: any) => (
    <li
      className="error-form-rejected"
      key={file.path}
      style={{ marginTop: '10px', width: '100%' }}
    >
      {file.path}
      <ul className="error-form-rejected">
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))
  const dropClasses = useMemo(
    () => `inputFile ${fileRejectionItems.length ? 'error-input' : ''}`,
    [fileRejectionItems],
  )

  const files = acceptedFiles.map((file: any) => (
    <span style={{ color: '#0E589B' }} key={file.path}>
      {file.path}
    </span>
  ))

  // validate email
  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  // validate url
  const validateUrl = (url: string) => {
    const re =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    return re.test(String(url).toLowerCase())
  }

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault()
      if (!validateUrl(linkedInUrl)) {
        toast.error('Please enter a valid url')
        if (urlLinkedinInputRef.current) {
          urlLinkedinInputRef.current.focus()
          urlLinkedinInputRef.current.style.borderColor = 'red'
        }
      } else if (acceptedFiles.length === 0 || fileRejections.length > 0) {
        toast.error('Please select a valid file')
      } else if (!name) {
        toast.error('Please enter a name')
      } else if (!phone) {
        toast.error('Please enter a phone')
      } else if (!leavingReason) {
        toast.error('Please enter a leaving reason')
      } else {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('name', name)
        formData.append('midName', midName)
        formData.append('lastName', lastName)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('linkedInUrl', linkedInUrl)
        formData.append('experience', experience)
        formData.append('relevantExperience', relevantExperience)
        formData.append('leavingReason', leavingReason)
        formData.append('jobRoleId', job && job.id ? job.id.toString() : '')
        formData.append('resume', acceptedFiles[0])
        formData.append('signature', GATSBY_SIGNATURE)

        GTM.sendEvent({
          event: GTMEventType.RequestJob,
          job_id: job?.id ?? '',
          job_name: job?.name ?? '',
          job_type: job?.jobType ?? '',
        })

        await axios.post(`${API_URL}/job-applies/apply`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        setIsLoading(false)

        GTM.sendEvent({
          event: GTMEventType.SuccessJobRequest,
          job_id: job?.id ?? '',
          job_name: job?.name ?? '',
          job_type: job?.jobType ?? '',
        })

        toast.success('We recieved your request!! Our team will reach you.')
        navigate('/career')
      }
      return
    } catch (error) {
      setIsLoading(false)

      GTM.sendEvent({
        event: GTMEventType.FailedJobRequest,
        job_id: job?.id ?? '',
        job_name: job?.name ?? '',
        job_type: job?.jobType ?? '',
      })

      toast.error('Error submiting')
    }
  }

  return (
    <section className="container" style={{ marginTop: '40px', marginBottom: '40px' }}>
      <div className="JobDetailWrapper">
        <div className="info-job-detail-wrapper">
          <h5>Job summary</h5>
          <p className="summary-detail">{job && job.summary}</p>
          <h5>Key Duties / Responsibilities / Accountabilities</h5>
          <ul>{job && job.keyDuties?.keyDuties.map((e, kd) => <li key={kd}>{e}</li>)}</ul>
          <br />
          <h5>Duties, Required Knowledge, Skills and Abilities:</h5>
          <ul>{job && job.duties?.duties.map((e, kd) => <li key={kd}>{e}</li>)}</ul>
          <br />
          <h5>Qualifications</h5>
          <div className="qualifications-details">
            <div style={{ width: '50%' }}>
              <h6>Minimum Qualifications</h6>
              <ul>
                {job &&
                  job.minQualifications?.minQualifications.map((e, kd) => <li key={kd}>{e}</li>)}
              </ul>
            </div>
            <div style={{ width: '50%' }}>
              <h6>Preferred qualifications</h6>
              <ul>
                {job &&
                  job.preferedQualifications?.preferedQualifications.map((e, kd) => (
                    <li key={kd}>{e}</li>
                  ))}
              </ul>
            </div>
          </div>
          <br />
          <h5>Environment and Cognitive / Physical Demands</h5>
          <div className="qualifications-details">
            <div style={{ width: '50%' }}>
              <h6>Environment And Cognitive</h6>
              <ul>{job && job.enviroment?.enviroment.map((e, kd) => <li key={kd}>{e}</li>)}</ul>
            </div>
            <div style={{ width: '50%' }}>
              <h6>Physical demands</h6>
              <ul>
                {job && job.physicalDemands?.physicalDemands.map((e, kd) => <li key={kd}>{e}</li>)}
              </ul>
            </div>
          </div>
        </div>
        <div className="form-jobdetail-wrapper">
          <>
            <h5>SUBMIT YOUR APPLICATION</h5>
            <p>Send us your CV and join us on board!</p>

            <div {...getRootProps({ className: `${dropClasses} upload-cv-space` })}>
              <input {...getInputProps()} name="resume" />

              <img src="/images/icon-park-outline_upload-logs.svg" />
              <div className="attach-info">
                <h5>Attach your Resume</h5>
                <p>PDF, DOC (Max. 10MB)</p>
              </div>
            </div>
            <div className="selected-file" style={{ width: '100%' }}>
              {files}
            </div>
            <div style={{ color: 'red', width: '100%', overflow: 'hidden' }}>
              {fileRejectionItems}
            </div>
          </>
          <>
            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="First Name">First Name</label>
                <input
                  className="form-control"
                  id="First Name"
                  autoComplete="off"
                  type="text"
                  placeholder="First Name*"
                  value={name}
                  name="name"
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="MiddleName">Middle Name</label>
                <input
                  className="form-control"
                  id="MiddleName"
                  autoComplete="off"
                  type="text"
                  placeholder="Middle Name"
                  value={midName}
                  name="midName"
                  onChange={(e) => setmidName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Last Name">Last Name</label>
                <input
                  className="form-control col"
                  id="Last Name"
                  autoComplete="off"
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  name="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Email">Email</label>
                <input
                  className="form-control"
                  id="Email"
                  autoComplete="off"
                  type="email"
                  placeholder="Email*"
                  value={email}
                  name="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Phone Number">Phone Number</label>
                <input
                  className="form-control"
                  id="Phone Number"
                  autoComplete="off"
                  type="text"
                  placeholder="Phone number*"
                  value={phone}
                  name="phone"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <h5>LINKEDIN & EXPERIENCE</h5>
              <div className="form-group">
                <label htmlFor="Linkedin URL">Linkedin URL</label>
                <input
                  ref={urlLinkedinInputRef}
                  className="form-control"
                  id="Linkedin URL"
                  autoComplete="off"
                  type="text"
                  placeholder="LinkedIn URL"
                  value={linkedInUrl}
                  name="linkedInUrl"
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>
              <div className="row">
                <div className="form-group col">
                  <label htmlFor="Total Experience (in years)">Total Experience (in years)</label>
                  <input
                    className="form-control"
                    id="Total Experience (in years)"
                    autoComplete="off"
                    type="number"
                    placeholder="Total Experience (in years)"
                    value={experience}
                    name="experience"
                    onChange={(e) => setTexp(e.target.value)}
                  />
                </div>
                <div className="form-group col">
                  <label htmlFor="Total Experience (in years)">
                    Relevant Experience (in years) *
                  </label>
                  <input
                    className="form-control"
                    autoComplete="off"
                    type="number"
                    placeholder="Relevant Experience (in years) *"
                    value={relevantExperience}
                    name="relevantExperience"
                    onChange={(e) => setRexp(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="lrs">Reason of leaving current / previous organization</label>
                <textarea
                  rows={5}
                  cols={5}
                  className="form-control"
                  id="lrs"
                  autoComplete="off"
                  placeholder="Reason of Leaving Current/Previous Organisation: *"
                  value={leavingReason}
                  name="leavingReason"
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>

              <button disabled={isLoading} type="submit" className="btn-submit-cv">
                {isLoading ? 'SUBMITING...' : 'APPLY'}
              </button>
            </form>
          </>
        </div>
      </div>
    </section>
  )
}

export default JobDetailsP
