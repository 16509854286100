import React, { useEffect, useState } from 'react'
import Layout from '@/components/Layout'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import BreadCumbs2 from '@/components/Common/BreadCumbs2'
import JobDetailsP from '@/components/Careers/Details'
import { API_URL } from '@/npc-api/config'
import axios from 'axios'
import { jobsProp } from '@/components/Careers/ListJob'
import { navigate } from 'gatsby'
const JobDetail = ({ params }: any) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setError] = useState(false)
  const [jobRole, setJobRole] = useState<jobsProp | undefined>()
  const { id } = params
  const fetchJobRole = async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(`${API_URL}/job-roles/${id}`)
      setJobRole(res.data)
      setIsLoading(false)
      if (!res.data) {
        navigate('/career')
      }
    } catch (error) {
      setIsLoading(false)
      setError(true)
      navigate('/career')
    }
  }

  useEffect(() => {
    if (!id) {
      navigate('/career')
    }
    fetchJobRole()
  }, [])
  return (
    <Layout>
      <SEO title="JobDetail" canonical={useLocation().host} />
      <Header />
      <BreadCumbs2
        title={jobRole ? jobRole.name : ''}
        location={jobRole ? jobRole.location : ''}
        date={jobRole ? jobRole.createdAt : undefined}
        jobType={jobRole ? jobRole.jobType : ''}
        imgUrlPath="/images/banner-two.webp"
      />
      <JobDetailsP job={jobRole} />
      <Footer />
    </Layout>
  )
}

export default JobDetail
