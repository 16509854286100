import React from 'react'
import { format } from 'date-fns'
import { parseDate } from '../Careers/ListJob'
import { navigate } from 'gatsby'
// import BackgroundImage from 'gatsby-background-image'
// import { BGImage } from '../Home/Banner'

type Props = {
  title: string
  imgUrlPath?: string
  location?: string
  date?: string | undefined
  jobType?: string
}

const BreadCumbs2: React.FC<Props> = ({ title, imgUrlPath, location, date, jobType }) => {
  return (
    <div
      className="section-bg section-padding BreadCumbs2"
      style={{
        backgroundImage: imgUrlPath ? `url(${imgUrlPath})` : `url(/images/banner-two.webp)`,
      }}
    >
      {/* // <BackgroundImage */}
      {/* //   className={'section-bg section-padding BreadCumbs2'} */}
      {/* //   fluid={BGImage('subHeader2.webp')} */}
      {/* // > */}
      <div className="breadcumb-overlay2"></div>
      <div className="container">
        <div className="row">
          <div className="col-12 ">
            <h1 className="title-job-detail text-md-left text-center">{title}</h1>
            <div className="tags-wrapper">
              <div className="tags-wrapper-icon">
                <img src="/images/mdi_map-marker-outline.svg" />
                <span className="location-icon-text ">{location}</span>
              </div>
              <div className="tags-wrapper-icon">
                <img src="/images/clock.svg" />
                <span className="location-icon-text ">{jobType}</span>
              </div>
              <div className="tags-wrapper-icon">
                <img src="/images/datecalendar.svg" />
                <span className="location-icon-text ">
                  {format(parseDate(date), 'MMMM d, yyyy')}
                </span>
              </div>
            </div>
            <span className="back-btn-details mx-auto mx-md-0 " onClick={() => navigate('/career')}>
              <img src="/images/mdi_chevron-left.svg" alt="img" />
              Back to Careers
            </span>
          </div>
        </div>
      </div>
      {/* </BackgroundImage> */}
    </div>
  )
}

export default BreadCumbs2
